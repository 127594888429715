<template>
    <router-view></router-view>
</template>

<script>
export default {
    name:'ReturnWaybill',
    data(){
        return{

        }
    }
}
</script>

<style>

</style>